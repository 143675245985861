import React, { useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  Input,
  Button,
} from "reactstrap"
import { graphql } from "gatsby"
import { useQueryParam, StringParam } from "use-query-params"

import { LazyLoadImage } from "react-lazy-load-image-component"
import Select from "react-select"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Article from "../components/articleComponent"

const UseQueryParamExample = (props) => {
  const { allSanityArticle } = props.data
  const alldata = allSanityArticle.edges.map((t) => t.node)

  const [selectedOption, setSelectedOption] = useState(null)
  const [optionList, setOptionList] = useState([])
  const [categoryItems, setCategoryItems] = useState(alldata)
  // const [catFinal, setCatFinal] = useState([])
  const [search, setSearch] = useState('')
  const [searchItems, setSearchItems] = useState(alldata)
  // const [searchQuery, setSearchQuery] = useQueryParam("search", StringParam)

  // Other
  useEffect(() => {
    if (props.location.pathname.includes("/articles")) {
      const customClass = document.getElementById("gatsby-focus-wrapper")
      customClass.setAttribute("class", "forArticles")
    } else {
      return null
    }
  }, [props.location.pathname])

  let filterData = []

  const doSearch = () => {
    const searchLower = search ? search.toLowerCase() : null
    filterData = searchLower
      ? alldata.filter(
          t =>
            t?.title?.toLowerCase().includes( searchLower ) ||
            t?.body?.toLowerCase().includes( searchLower )
        )
      : alldata
    setSearchItems( filterData )
  }
  /*
  if ( searchQuery ) {
    console.log( 'Search set to (search query)', searchQuery )
    setSearch( searchQuery )
    doSearch()
  }
  */
  const handleSearchChange = e => {
    setSearch( e.target.value )
    doSearch()
  }

  // FOR CATEGORIES
  useEffect(() => {
    const optionsField = allSanityArticle.group.map((item) => {
      return { value: item.fieldValue.toLowerCase(), label: item.fieldValue }
    })
    setOptionList(optionsField)
  }, [])

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption)
    const cat = alldata.filter(
      (item) => item?.categories[0]?.title === selectedOption?.label
    )
    setCategoryItems(cat)
  }

  const handleSubmit = e => {
    e.preventDefault()
    doSearch()
  }

  return (
    <Layout location={props.location}>
      <Seo title="Article" />
      <div className="hero-adjustment">
        <div className="container">
          <div className="hero-content-wrapper">
            <div className="row no-gutters">
              <div className="col-12 col-md-7">
                <div className="hero-dog-bg">
                  <div className="dog-bg"></div>
                </div>
              </div>
              <div className="col-12 col-md-5">
                <div className="hero-video">
                  <div>
                    <div>
                      <div className="video-modal-btn no-video">
                        <LazyLoadImage
                          effect="blur"
                          src="/assets/img/tplo-video.jpg"
                          alt="video"
                          className="video-img img-fluid v-h"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="inner-page-title article-wrap-title text-right arthroscopy-hero">
              <h1>Articles</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="page-content article">
        <Container className="py-5">
          <Row className="justify-content-center">
            <Col md={10} sm={12}>
              <div className="row">
                <div className="col-md-6 my-2">
                  <h4>Search an Article</h4>
                  <form onSubmit={handleSubmit}>
                    <InputGroup>
                      <Input
                        type="search"
                        name="search"
                        id="searhform"
                        onChange={handleSearchChange}
                        style={{
                          margin: 0,
                          height: "auto",
                          border: "1px solid #0077b3",
                          borderBottom: "1px solid #0077b3",
                        }}
                        placeholder={search ? search : "Type article title..."}
                      />
                      <InputGroupAddon addonType="append">
                        <Button
                          onClick={doSearch}
                          className="article-submit-btn"
                          style={{
                            fontSize: "15px",
                            padding: "8px 10px",
                          }}
                        >
                          Search
                        </Button>
                      </InputGroupAddon>
                    </InputGroup>
                  </form>
                </div>
                <div className="col-md-6 my-2">
                  <h4>Select Article Category</h4>
                  <Select
                    value={selectedOption}
                    onChange={handleChange}
                    options={optionList}
                  />
                </div>
              </div>
              <Article allSanityArticle={search ? searchItems : categoryItems} />
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}
export default UseQueryParamExample

export const articlequery = graphql`
  query articleQuery {
    allSanityArticle(
      filter: { isUnpublishedNotHidden: { ne: true } }
      sort: { fields: publishedAt, order: DESC }
    ) {
      edges {
        node {
          id
          title
          body
          authors {
            person {
              id
              name
            }
          }
          banner {
            asset {
              url
            }
          }
          categories {
            title
          }
          publishedAt(formatString: "MMMM DD, YYYY")
        }
      }
      group(field: categories___title) {
        fieldValue
        totalCount
      }
    }
  }
`
