import React, { useState } from "react"
import marked from "marked"
import { Row, Col } from "reactstrap"
import { LazyLoadImage } from "react-lazy-load-image-component"

import JwPagination from "./pagination"
import blank from "../images/no_Image.png"

const ArticleComponent = ({ allSanityArticle }) => {
  const [pageOfItems, setPageOfItems] = useState([])

  const onChangePage = (pageOfItems) => {
    setPageOfItems(pageOfItems)
  }
  const customLabels = {
    first: <i className="fa fa-angle-double-left" />,
    last: <i className="fa fa-angle-double-right" />,
    previous: <i className="fa fa-angle-left" />,
    next: <i className="fa fa-angle-right" />,
  }
  // console.log("pageOfItems", pageOfItems)
  return (
    <>
      <div className="article-body border-top justify-content-center">
        {pageOfItems &&
          pageOfItems.map((article) => (
            <div className="my-5 article-wrapper" key={article.id}>
              <Row>
                <Col md={4} className="px-0">
                  <div className="article-body-wrap">
                    <div className="p-3 text-center border border-white">
                      <h3 className="mb-0">{article.title}</h3>
                    </div>

                    {article.body ? (
                      <div
                        className="mt-5"
                        dangerouslySetInnerHTML={{
                          __html: marked(article.body),
                        }}
                      />
                    ) : null}
                  </div>
                </Col>
                <Col md={8} className="px-0">
                  <LazyLoadImage
                    effect="blur"
                    className="img-fluid"
                    src={
                      (article?.banner && article?.banner?.asset?.url) || blank
                    }
                    alt={
                      (article?.banner && article?.banner?.alt) ||
                      article?.title
                    }
                  />
                </Col>
              </Row>
            </div>
          ))}

        <JwPagination
          items={allSanityArticle}
          onChangePage={onChangePage}
          pageSize={20}
          labels={customLabels}
        />
      </div>
    </>
  )
}
export default ArticleComponent
